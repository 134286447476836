#navbar{
    background-color:#EBA9AA;
    border-bottom: 1px solid white;
    /* width:100vw; */
    z-index:1;
    margin:auto;
    text-align: center;
    justify-content: center;
}

#navbar-logo{
    width: auto;
    height:120px;
    padding-left:10%;
    margin:0;
}


.nav-link{
    text-align: center;
    font-family: "Cinzel";
    color:white;
    margin-left:50px;
    margin-right:50px;
}

#navbar-nav{
    
    padding-top: 20px;
}
.navbar-nav  a:hover {
    color:rgb(200,230,240);
    /* margin-left:50px;
    margin-right:50px; */
}

@media (max-width:480px) {
    #navbar-logo{
        height:140px;
    }
    #navbar-nav{
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top:25px;
    }
    .nav-link{
        margin-left:auto;
        margin-right:auto;
    }
}
@media (max-width:1000px) {
    #navbar-nav{
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top:25px;
    }
    .nav-link{
        margin-left:auto;
        margin-right:auto;
    }
}