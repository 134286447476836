
.display-date{
    display:flex;
    text-align: center;
    width:22%;
    height:100px;
    color : #4b3751;
    padding:10px;
    margin:10px;
    /* object-position: bottom center; */
    /* border-top :1px solid #4b3751;
    border-bottom :1px solid #4b3751; */
    
    
    background:rgba(255, 255, 255, 0.4);
    /* border-radius: 100% 100% 100% 100%; */
    font-size: x-large; 
    
}
.display-date-p {
    font-size: x-large; 
    font-family: adage-script-jf, sans-serif;
}
#countdown-header{
    background-color:#EBA9AA;
    width:100%;
    padding-bottom: 50px;
    margin-bottom: 50px;
    margin:0;
}
@media (max-width:480px) {
    #countdown-header{
        padding-left:1%;
        padding-right:1%;
    }
    .display-date{
        display:flex;
        text-align: center;
        width:90px;
        height:90px;
        color : #4b3751;
        padding:10px;
        margin:2px;
        margin-bottom: 10px;
        /* object-position: bottom center; */
        /* border-top :1px solid #4b3751;
        border-bottom :1px solid #4b3751; */
        
        background:rgba(255, 255, 255, 0.4);
        /* border-radius: 100% 100% 100% 100%; */
        
    }
}