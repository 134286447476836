#header-infos{
    margin:0;
    padding-top:20px;
    background-color:#EBA9AA;
    color:rgb(255, 255, 255);
    font-family : "Mulish";

}
#header-infos-title{
    justify-content: center;
    width:90%;
}


#header-infos-title > .row > h3{
    width:fit-content;
    font-size: xxx-large;
    text-align: center;
    margin-left:auto;
    margin-right:auto;
    padding-left: 0px;
    padding-right: 0px;
}
#header-infos-title > .row > h4{
    width:fit-content;
    padding-left: 0px;
    padding-right: 0px;
    margin-left:auto;
    margin-right:auto;
    text-align: center;
    font-size: x-large;
}

#header-pic{
    width:100%;
}

@media (max-width:480px){
    #header-infos-title > .row > h4{
        font-size: larger;
        padding-left: 0px;
        padding-right: 0px;
        margin:auto;
    }
    #header-infos-title > .row {
        margin-left:0px;
        margin-right:0px;
    }
    #header-infos-title > .row > h3{
        font-size: xxx-large;
        padding-left: 0px;
        padding-right: 0px;
        

    }
    #header{
        overflow: hidden;
    }
    #header-pic{
        transform:translate(-35%);
        transform: scale(1.2);
        /* object-fit: cover; */
        /* height:50%; */
        width:100%;
        overflow: hidden;
    }
    
}