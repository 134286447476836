

html{
    width:100%;
}

#mairie, #houppa, #soiree{
    text-align: center;
    width:auto;
}
.famille{
    /* font-family: palatino, sans-serif; */
    font-family: "Dosis", sans-serif;
    font-size: x-large;
    font-style: italic;
    font-weight: 500;
    margin-bottom:50px;
    
}
.titre{
    font-family: "Cinzel Decorative", sans-serif;
    font-size: 70px;
    color:rgb(227, 161, 161);
    /* color:rgb(227, 161, 161); */
    font-style: normal;
    margin-top:20px;
    margin-bottom:40px;
}
/* .titre-henne{
    font-family: adage-script-jf, sans-serif;
    font-size: 50px;
    /* color:rgb(227, 161, 161); 
    color:rgb(0,0,0);
    font-style: normal;
    margin-top:20px;
    margin-bottom:40px;
} */
.importante-oui{
    font-family: "adage-script-jf", sans-serif;
    /* font-size: xxx-large; */
    
    /* font-family: krul, sans-serif; */
    font-size: 50px;
    font-style: italic;
    /* color:rgb(1, 1, 1); */
    color:rgb(227, 161, 161);
    font-style: normal;

}
.hebreux-phrase{
    margin-left: auto;
    margin-right: auto;
    margin-bottom:30px;
    width:15vw;
    height:auto;
    
}
.mairie{
    justify-content: center;
    margin:auto;
}

.mairie > .col > p{
    font-size : 100px;
}

@media (max-width:480px){
    .titre{
        font-size: 50px;
        /* color:rgb(227, 161, 161); */
        color:rgb(227, 161, 161);
        font-style: normal;
        margin-top:20px;
        margin-bottom:40px;
    }
    .famille{
        /* font-family: palatino, sans-serif; */
        font-family: "Dosis", sans-serif;
        font-size: large;
        font-style: normal;
        margin-bottom:10px;
        
    }
    .mairie{
        justify-content: center;
         margin:auto;
    }
    .hebreux-phrase{
        margin-bottom:30px;
        width:20vh;
        height:auto;
    }
}

.logo{
    text-align:center;
    margin-bottom:15px;
}

.solenel{
    text-align: center;
    font-style: italic;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    /* font-family: adage-script-jf, sans-serif; */
    font-size: small;
    margin-bottom:15px;
    
}
.solenel-honneur{
    text-align: center;
    font-style: italic;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    /* font-family: adage-script-jf, sans-serif; */
    font-size: 20px;
    margin-bottom:15px;
    
}
.solenel-code{
    text-align: center;
    font-style: italic ;
    text-decoration: underline;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    /* font-family: adage-script-jf, sans-serif; */
    font-size: 20px;
    margin-bottom:15px;
    
}

.message{
    font-family: 'Gill Sans', 'Gill Sans MT', sans-serif;
    font-size: large;
    font-style: normal;
    text-align:center;
    margin-bottom:15px;
    
}

.infos{
    font-family: 'Gill Sans', 'Gill Sans MT', sans-serif;
    font-size: x-large;
    font-weight: 200;
    text-align:center;
    margin-bottom:15px;
}
.mairie{
    font-family: "Mulish", sans-serif;
    font-size: 60px;
    margin:auto;
    /* font-weight: 600; */
    font-style: normal;
    margin-bottom:15px;
}
.maman{
    font-family: "krul", sans-serif;
    font-size: 40px;
    font-style: normal;
    margin-bottom:15px;
}
#pic-intro{
    width: 300px;
    height: auto;
    padding-left:0px;
    padding-right:0px;
    box-shadow:-3px 2px 3px #EBA9AA, 3px 2px 3px #EBA9AA, -3px -2px 3px #EBA9AA, 3px -2px 3px #EBA9AA;
}


