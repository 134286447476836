.App {
  text-align: center;
}

body{
  /* width:100%; */
  background-color: #F5DCCF;
}

.container-fluid{
  padding-left:0px;
  padding-right:0px;
}

.row {
  margin-right:0px;
}

.col {
  padding-right:0px;
  padding-left:0px;
}
.btn-primary{
  --bs-btn-bg:#EBA9AA;
  --bs-btn-border-color:var(--bs-btn-bg);
  --bs-btn-hover-bg:#f9b3b4;
  --bs-btn-hover-border-color:white;
  --bs-btn-active-bg:#f9b3b4;
  --bs-btn-active-border-bg:#f9b3b4;
}