#introduction{  
  position: relative;
  color:black;
  font-family: "Mulish"; 
  background-color: #F5DCCF;
  height:100vh;
  width:100vw;
  text-align:center;
  z-index:2;
  margin:auto;
  background-image: url("..\/..\/assets\/intro.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

#introduction-infos {
  position:absolute;
  width:auto;
  margin:0;

  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
  right:auto;
}


#introduction-infos > .col > h3{
  font-size: xxx-large;
}
#introduction-title{
  /* position:relative; */
  /* top:calc(50% - 700px/2); */
  /* left:auto; */
  font-size: xxx-large;
  opacity: 0;
}

#introduction-infos-pic{
  /* box-shadow:-3px 2px 3px #EBA9AA, 3px 2px 3px #EBA9AA, -3px -2px 3px #EBA9AA, 3px -2px 3px #EBA9AA; */
  height:100vh;
  width:auto;
  margin:auto;
  margin-top:30px;
  /* -webkit-mask-image:-webkit-gradient(linear, left top,left bottom, from (rgba(0,0,0,1)), to (rgba(0,0,0,0))); */
}

#introduction-infos-button{
  border-radius: 0px;
  opacity: 0;

}

@media (max-width:480px){
  #introduction{
    max-height:90%;
    max-width:100%;
    background-position: center;
    /* font-size:x-large; */
  }
  #introduction-infos-pic{
    height:50vh;
  }
}